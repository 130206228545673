.Home {
  text-align: center;
}

.Home-header {
  background-image: url(/tiles-transparent.png);
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  mix-blend-mode: screen;
  color: white;
  text-shadow: 0.4vmin 0.4vmin #090172;
}

.Home-link {
  color: rgb(146, 224, 255);
  mix-blend-mode: screen;
  text-shadow: 0.4vmin 0.4vmin #090172;
}